<template>
  <Start></Start>
</template>

<script>
import Start from "@/views/Start";

export default {
  name: "Home",
  components: { Start },
};
</script>
