<template>
  <v-row no-gutters>
    <v-col cols="12" md="6" class="primary d-flex justify-center align-center">
      <v-card outlined class="transparent">
        <v-card-text>
          <v-img
            :src="
              require(`@/assets/core/logo_${
                $vuetify.theme.dark ? 'light' : 'dark'
              }.png`)
            "
            height="200vh;"
            max-height="1200px"
          />
        </v-card-text>

        <v-card-title> Welcome at Trusted Accountant</v-card-title>
        <v-card-text
          >Prefer another color scheme click on the button</v-card-text
        >
        <div class="text-center">
          <v-btn class="secondary" @click="toggle_dark_mode">
            <v-icon left>mdi-theme-light-dark</v-icon>
            <span>Dark/Light</span>
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" class="justify-center align-center">
      <div class="ma-5 pa-0">
        <vimeo-player
          :video-id="vimeo.intro"
          class="embed-container"
          :options="{ responsive: true }"
        ></vimeo-player>

        <br />
        Dear stakeholder in creating a family status for the family
        {{ family.data.family_name }}.<br /><br />

        My name is {{ family.data.user.first_name }}
        {{ family.data.user.last_name }} and on behalf of Trusted Accountant I
        am assisting me the {{ family.data.family_name }} family in describing
        the Family Charter.<br />
        <br />

        You are invited to participate in the family meetings. Prior to each
        family meeting, we ask all participants for input on particular
        perspectives on the family business.
        <br />

        <br />
        With the Family Charter app, we offer you the opportunity to give your
        input on the basis of a structured questionnaire. using a structured
        questionnaire. Your input gives us a better understanding of your vision
        of the future of the family business and the role of the family in it.
        Together with the views of the other participants we have a complete
        picture of the wishes and expectations. This enables us to work in a
        more focused way during the family meetings towards Establishing the
        agreements that the family wants to make.
        <br />
        On the next page you will enter your personal section of the Family
        Charter app that we have prepared for you.
        <br />
        <br />

        You can go through the Family Charter app in your own time and pace and
        complete. For each subsequent family meeting there will be new modules
        will be prepared for you. If you have any questions or comments about
        the Family Charter app, then you can reach me at the following contact
        information. <br />
        <br />Thank you in advance and until the next family gathering.<br />
        Kind regards,<br />
        <br />
        <br />
        {{ family.data.user.first_name }} {{ family.data.user.last_name }}<br />
        <br />

        <h5>
          TrustedAccountant
          <br />

          {{ family.data.user.first_name }} {{ family.data.user.last_name
          }}<br />
          e-mail: {{ family.data.user.email }}<br />
          telefophone: {{ family.data.user.telephone }} <br />
        </h5>
        <div class="text-end mr-15">
          <v-btn
            elevation="15"
            class="primary"
            @click="start"
            :disabled="!valid"
            :class="{ 'primary white--text': valid, disabled: !valid }"
            >Continue</v-btn
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import vimeoData from "@/text/vimeo.json";
import { mapGetters } from "vuex";

export default {
  name: "Intro",
  data() {
    return {
      companyName: null,
      firstNameUser: null,
      lastNameUser: null,
      emailUser: null,
      telephoneUser: null,
      valid: true,
      vimeo: vimeoData,
    };
  },
  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    start() {
      this.$router.push({ name: "Login" });
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      this.$vuetify.theme.dark = theme === "true";
    }
    this.firstNameUser = JSON.parse(localStorage.getItem("firstNameUser"));
    this.lastNameUser = JSON.parse(localStorage.getItem("lastNameUser"));
    this.companyName = JSON.parse(localStorage.getItem("companyName"));
    this.emailUser = JSON.parse(localStorage.getItem("emailUser"));
    this.telephoneUser = JSON.parse(localStorage.getItem("telephoneUser"));
  },
  computed: {
    ...mapGetters("auth", ["family"]),
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}

.height-col {
  height: 100vh;
}
</style>
